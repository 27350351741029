<template>
  <div class="qrview">
    <p class="qr-title">{{title}}</p>
    <!--    <vue-qr :logoSrc="logo.icon" :text="uri" :size="192"></vue-qr>-->
    <!--    <img style="width: 192px;height: 192px;border:10px solid white;" :src="qrimage" id="qrcode"/>-->
    <!--    <svg v-html="qrimage"/>-->
    <qrcode id="qrCode" :url="uri" :iconurl="logo.icon" :wid="182" :hei="182" :imgwid="30" :imghei="30"
            style="border:10px solid white;"></qrcode>
    <p class="qr-mername">{{ merName }}</p>
    <img class="qr-bottom" :src="qr_bottom"/>
  </div>
</template>

<script>

// import vueQr from 'vue-qr'
import qrcode from 'vue_qrcodes'
// import AraleQRCode from 'arale-qrcode'
import Vue from "vue";

export default {
  name: "qrcodeview",
  data() {
    return {
      title:"扫码支付",
      logo: {
        "icon": require("../../assets/image/pay/icon_nonghang.png")
      },
      qr_bottom: require("../../assets/image/home/icon_qr_bottom.png"),
      merName: "",
      uri: "",
      qrimage: "",
      templeName: "",
      is_temple:true,
    }
  },
  methods: {
    // closeQRView() {
    //   this.$store.commit("inputQRView", false)
    // },
    renderQRCode() {


    },
    getTempleInfo() {
    },

  },
  mounted() {
    this.getTempleInfo()

    this.merName = localStorage.getItem("merchantName");
    this.uri = window.location.protocol + "//" + window.location.host + "/#/h5/pay?merchantId=" + localStorage.getItem("merchantId");
    let is_temple = localStorage.getItem("is_temple")
    // console.log(is_temple)
    if (is_temple == "true") {
      this.uri = window.location.protocol + "//" + window.location.host + "/#/h5/apply?merchantId=" + localStorage.getItem("merchantId");
      this.qr_bottom = require("../../assets/image/login/icon_qr_bottom_reg.png");
      // this.merName = ""
      this.title = "我的邀请码"
      this.is_temple = false
    }else{
      this.qr_bottom = require("../../assets/image/home/icon_qr_bottom.png");
    }
    let merchantLogo = localStorage.getItem("merchantLogo")
    if (merchantLogo!="null") {
      this.logo.icon = merchantLogo
    }else{
      this.logo.icon = require("../../assets/image/pay/icon_nonghang.png")
    }

    // console.log(this.uri)
    // const result = new AraleQRCode({
    //   render: "canvas",  /*  生成的类型 'svg' or 'table dom元素类型 */
    //   text: this.uri, /* 二维码的链接*/
    //   image: 'https://hsjr-staging.heshang0512.top/img/icon_merchant.4b94dae2.png',
    //   imageSize: 30,
    //   size: 187       /* 二维码的大小 */
    // })
    // this.qrimage = Vue.extend({
    //   template: result
    // });
    // this.$refs.qrcode = result;
    // console.log(result)
    // this.qrimage = result;
    // const svgXml = new XMLSerializer().serializeToString(result)
    // const src = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(svgXml)))
    // this.qrimage = src
    // console.log()

  },
  components: {
    // vueQr
    qrcode,
  },
  props: [
    // "merName",
    // "merId"
  ]
}
</script>

<style scoped>
.qrview {
  background: url('../../assets/image/home/icon_qr_up.png');
  background-size: 100% 100%;
  width: 280px;
  height: 440px;
  /*margin-left: calc(50vw - 140px);*/
  margin-top: -88px;
  /*overflow: hidden;*/
  background-color: transparent !important;
}

.qr-title {
  font-size: 30px;
  width: 200px;
  /*margin-top: -30px;*/
  padding-top: 32px;
  margin-left: 40px;
  text-align: center;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 40px; /**/
}

.qr-mername {
  width: 280px;
  text-align: center;
  height: 64px;
  text-align: center;
  line-height: 52px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #5E4214;
}

.qr-bottom {
  width: 280px;

}

/deep/ .qrcode .logoimg{
  border-radius: 15px;
  background-color: transparent;
}

/deep/ .qrcode{
  background-color: transparent;
}
</style>